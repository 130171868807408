import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toSaveApplicationInput } from 'utils';
import { isFeatureOn } from 'featureToggles';
import { useHandleApiErrors } from '../useHandleApiErrors';

export const SAVE_APPLICATION_GQL = gql`
  mutation saveApplication($input: SaveApplicationInput!) {
    saveApplication(input: $input) {
      status
    }
  }
`;

export function useSaveApplication({ storeState, onSuccess, onError }) {
  const { applicationId, acquisition } = storeState;
  const promoCode = isFeatureOn('promoCode')
    ? acquisition.overview?.promoCode === ''
      ? undefined
      : acquisition.overview?.promoCode
    : undefined;

  const { handleError } = useHandleApiErrors('apollo', onError);
  const [saveApplication, { loading }] = useMutation(SAVE_APPLICATION_GQL, {
    ...(onSuccess
      ? {
          onCompleted: data => {
            onSuccess(data.saveApplication);
          },
        }
      : {}),
    onError: err => {
      handleError(err);
    },
  });

  const save = useCallback(
    formData => {
      if (formData) {
        const input = { applicationId, promoCode, ...toSaveApplicationInput(formData, storeState) };
        saveApplication({ variables: { input } });
      }
    },
    [applicationId, promoCode, storeState, saveApplication],
  );

  return {
    save,
    loading,
  };
}
