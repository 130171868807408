import React from 'react';
import { LayoutPage, LayoutContent, AcceptedCreditLimit, BenefitInfo, Form } from 'components';
import { Container, Text } from 'basis';
import { useFormInitialValues } from 'hooks';
import { useTheme } from '@emotion/react';
import { maxAUCreditLimit } from '_config/au/constants';

export const DclReferred = ({ onSubmit, submitting }) => {
  const initialValues = useFormInitialValues('confirmReferredCreditLimit');
  const theme = useTheme();

  return (
    <LayoutPage>
      <Container margin="0 0 13 0" hasBreakpointWidth>
        <Form
          id="confirmReferredCreditLimit"
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButtonLabel="Submit"
          showSaveDraftButton
          disableFormCache
          saveDraftOnSubmit
          saveDraftOnSessionTimeout
        >
          {formData => {
            return (
              <>
                <LayoutContent rowsGap="0" noSessionTimeout>
                  <Container margin="8 0 0 0">
                    <Text as="h1" textStyle="heading5" textStyle-md="heading4" color={theme?.headingColor}>
                      Choose your credit card limit
                    </Text>
                    <Container margin="10 0 5 0">
                      <Text>
                        Please consider your needs, when deciding a credit card limit. It is important that you are{' '}
                        comfortable with your credit limit*.
                      </Text>
                    </Container>
                    <AcceptedCreditLimit formData={formData} maxCreditLimit={maxAUCreditLimit} />
                    <Container margin="8 0" />
                    <BenefitInfo />
                  </Container>
                </LayoutContent>
              </>
            );
          }}
        </Form>
      </Container>
    </LayoutPage>
  );
};
