import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreValue } from 'store';
import { LayoutPage, Form, LayoutContent, ButtonToggle, Address, Errors } from 'components';
import { Container, Text, RadioGroup, Flex, Message, Link, List, Stack } from 'basis';
import PremiereCardImg from 'images/premiere-card.png';
import PrestigeCardImg from 'images/prestige-card.png';
import TwentyEightDegreesCardImg from 'images/28d-card-vertical.png';
import GoCardImg from 'images/go-card.png';
import GemAuCardImg from 'images/gem-au-card.png';
import LowRateCardImg from 'images/low-rate-card.png';
import { byCard, submitHandler } from 'utils';
import { useEventTracking } from 'react-event-tracker';
import { getDataLayerElements } from 'utils/getDataLayerElements';
import AchDetails from 'ach-details';
import {
  config,
  AdditionalCardHolderLinks,
  PRODUCT_CODE_DAVID_JONES_PRESTIGE,
  PRODUCT_CODE_DAVID_JONES_PREMIERE,
  PRODUCT_CODE_TWENTY_EIGHT_DEGREES,
  PRODUCT_CODE_GO,
  PRODUCT_CODE_AU_GEM,
  PRODUCT_CODE_LOW_RATE,
} from '_config';
import styled from '@emotion/styled';
import WalletIcon from 'images/icons/finance-wallet.svg';
import { PrivacyNoticeModal as DjPrivacyNoticeModal } from 'views/landing-page-dj/PrivacyNoticeModal';
import { PrivacyNoticeModal as AuPrivacyNoticeModal } from 'views/landing-page-au/PrivacyNoticeModal';
import { getRatesAndFees } from 'featureToggles';
import { useFormInitialValues, useErrorTrackingAndUpdateStore, useDataLayerAndAnalyticsLinks } from '../../hooks';
import { useSteps } from '../../hooks/useSteps';
import { useSaveAndSubmitApplication } from '../../hooks/useSaveAndSubmitApplication';

const FORM_ID = 'achDetails';

const CardImageArea = styled.div`
  display: flex;
  background: ${props => props.theme.colors.specialBackground};
`;

const CardImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto;
  #dj-card {
    height: ${props =>
      byCard({
        [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: '238px',
        default: '150px',
      })};
  }
  #wallet-icon {
    margin-right: 8px;
  }
  strong {
    font-family: 'montserrat', 'Medium';
  }
`;

const StyledList = styled.div`
  li::before {
    background-color: black;
    width: 0.3em;
    height: 0.3em;
  }
  li:not(:first-of-type) {
    margin-top: 2px;
  }
`;

export const AdditionalCardHolder = () => {
  const history = useHistory();
  const [storeState, updateStore] = useStoreValue();
  const { acquisition, ...otherState } = storeState;
  const { trackEvent } = useEventTracking();
  const initialValues = useFormInitialValues(FORM_ID);
  const { handleErrorCallback } = useErrorTrackingAndUpdateStore('Next');
  const { nextStep, pathname, isLastDraftStep } = useSteps();
  const submitButtonLabel = 'Next - Choose credit limit';
  const { trackEventSender } = useDataLayerAndAnalyticsLinks(storeState);
  const [privacyNoticeModalOpen, setPrivacyNoticeModalOpen] = useState(false);
  const openPrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(true);
    trackEventSender({
      action: 'interaction',
      location: 'additional-card-holder',
      label: 'Privacy Notice',
    });
  };

  const closePrivacyNoticeModal = () => {
    setPrivacyNoticeModalOpen(false);
  };

  const goToNextPage = useCallback(() => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-submitted',
        location: 'additional-card-holder',
        label: submitButtonLabel,
      },
      ...getDataLayerElements(storeState),
    });
    updateStore({
      activeStep: nextStep,
    });
    history.push(nextStep);
  }, [trackEvent, storeState, updateStore, nextStep, history]);

  const clearErrorAndGoNext = () => {
    updateStore({
      applicationErrors: null,
      applicationSubmitted: true,
    });
    goToNextPage();
  };

  const { saveAndSubmit, loading: loadingForSaveAndSubmit } = useSaveAndSubmitApplication({
    storeState,
    onError: handleErrorCallback,
    onSuccess: clearErrorAndGoNext,
  });

  const onSubmit = useCallback(
    ({ values, errors }) => {
      const val = values;
      if (values.sameCurrentAddress === 'yes') {
        val.currentAddress = null;
      }

      const newAcquisition = {
        ...acquisition,
        achDetails: { ...val },
      };

      const updateStoreFormValues = () => {
        updateStore({
          acquisition: newAcquisition,
        });
      };

      submitHandler({
        submit: () => {
          updateStoreFormValues();
          saveAndSubmit(newAcquisition);
        },
        errors,
      });
    },
    [acquisition, saveAndSubmit, updateStore],
  );

  const onClickNext = useCallback(
    ({ values, errors }) => {
      if (Object.keys(errors).length > 0) {
        return;
      }
      onSubmit({ values, errors });
    },
    [onSubmit],
  );

  const retrySubmit = () => {
    trackEvent({
      event: {
        category: 'application',
        action: 'application-submitted',
        location: pathname,
        label: 'Retry',
      },
    });

    onSubmit({ values: storeState.acquisition.additionalDebtsDetails });
  };

  if (otherState.applicationErrors) {
    return (
      <LayoutPage>
        <Errors
          applicationRef={otherState.applicationRef}
          retry={retrySubmit}
          retrying={loadingForSaveAndSubmit}
          isPreSubmission
        />
      </LayoutPage>
    );
  }

  const PrivacyNoticeModal = byCard({
    [PRODUCT_CODE_DAVID_JONES_PREMIERE]: DjPrivacyNoticeModal,
    [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: DjPrivacyNoticeModal,
    default: AuPrivacyNoticeModal,
  });

  return (
    <LayoutPage>
      <Form
        id={FORM_ID}
        initialValues={initialValues}
        showSaveDraftButton
        saveDraftOnSubmit={!isLastDraftStep}
        saveDraftOnSessionTimeout
        onSubmit={onClickNext}
        submitButtonLabel={submitButtonLabel}
      >
        {formData => {
          return (
            <LayoutContent rowsGap="6">
              <Container>
                <Text as="p">
                  An additional cardholder can make purchases and cash advances, request a replacement PIN and report
                  their card lost or stolen.
                </Text>
              </Container>
              <Container>
                <Text as="p">
                  They can’t change the credit limit, request a balance transfer, close the account or alter the{' '}
                  address.
                </Text>
              </Container>
              <Container>
                <Text as="p">
                  You, as the primary cardholder, are financially responsible for all additional card use, making{' '}
                  payments and any outstanding balance on the account. You can also cancel the additional card at any{' '}
                  time.
                </Text>
              </Container>
              <CardImageArea>
                <CardImageContainer>
                  <img
                    id="dj-card"
                    src={byCard({
                      [PRODUCT_CODE_DAVID_JONES_PREMIERE]: PremiereCardImg,
                      [PRODUCT_CODE_DAVID_JONES_PRESTIGE]: PrestigeCardImg,
                      [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: TwentyEightDegreesCardImg,
                      [PRODUCT_CODE_GO]: GoCardImg,
                      [PRODUCT_CODE_AU_GEM]: GemAuCardImg,
                      [PRODUCT_CODE_LOW_RATE]: LowRateCardImg,
                      default: '',
                    })}
                    alt="DJ card"
                  />
                  <Container margin="4 0 0 0">
                    <Flex>
                      <img id="wallet-icon" src={WalletIcon} alt="wallet-icon" />
                      <AdditionalCardFee additionalCardHolderFee={getRatesAndFees().additionalCardHolderFee} />
                    </Flex>
                  </Container>
                </CardImageContainer>
              </CardImageArea>
              <Text as="h4" textStyle="heading6">
                Do you want to add another cardholder to this account?
              </Text>
              <ButtonToggle
                name="hasAch"
                options={[
                  {
                    label: 'No',
                    value: 'no',
                  },
                  {
                    label: 'Yes',
                    value: 'yes',
                  },
                ]}
                testId="has-ach-toggle"
                validate={value => {
                  if (value === null) {
                    return 'Please select one option';
                  }
                  return null;
                }}
              />
              <Text>You can also add cardholder(s) later in the Latitude app or website</Text>

              {formData.state.values.hasAch === 'yes' && (
                <>
                  <Text as="h4" textStyle="heading5">
                    Please tell us about the additional cardholder
                  </Text>
                  <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
                    <Stack gap="3">
                      <Text>
                        By providing us information related to the additional cardholder, you confirm that you have been{' '}
                        authorised by the additional cardholder to provide Latitude with their personal information and{' '}
                        have informed the additional cardholder of Latitude’s{' '}
                        <Link onClick={openPrivacyNoticeModal}>Privacy Notice</Link> which contains important{' '}
                        information on how we manage their personal information and includes a link to our{' '}
                        <Link
                          newTab
                          href={AdditionalCardHolderLinks.privacy_policy.url}
                          onClick={() =>
                            trackEventSender({
                              action: 'external-link',
                              label: AdditionalCardHolderLinks.label,
                              location: 'additional-card-holder',
                            })
                          }
                        >
                          {AdditionalCardHolderLinks.privacy_policy.label}
                        </Link>
                        .
                      </Text>
                      <Text>
                        The information you provide in relation to the additional cardholder will be used to verify
                        their identity and may include the use of external sources of information for this purpose.
                      </Text>
                      <Text>Please provide the additional cardholders:</Text>
                      <StyledList>
                        <List>
                          <List.Item>full legal name (as shown on their ID)</List.Item>
                          <List.Item>date of birth</List.Item>
                          <List.Item>current residential address</List.Item>
                        </List>
                      </StyledList>
                    </Stack>
                  </Message>
                  <AchDetails
                    name="personalDetails"
                    countryCode="AU"
                    formData={formData}
                    testId="ach-details"
                    extraProps={{
                      hasMiddleName: {
                        showCircles: true,
                        columns: 1,
                        color: 'white',
                      },
                    }}
                  />
                  <RadioGroup
                    name="sameCurrentAddress"
                    label="Current residential address"
                    options={[
                      {
                        label: 'Address is the same as yours ',
                        value: 'yes',
                      },
                      {
                        label: 'No, additional cardholder lives at a different address ',
                        value: 'no',
                      },
                    ]}
                    showCircles
                    columns="1"
                    color="white"
                    testId="same-current-address"
                  />
                  {formData.state.values.sameCurrentAddress === 'no' && (
                    <Address
                      name="currentAddress"
                      label="Current residential address"
                      testId="ach-current-address"
                      allowPropertyName={false}
                      allowPO={false}
                      countryCode={config.countryCode}
                      autoAddressErrorMessage="Please enter your current residential address."
                      autoAddressHelpText=""
                      formData={formData}
                    />
                  )}
                </>
              )}
            </LayoutContent>
          );
        }}
      </Form>
      <PrivacyNoticeModal isOpen={privacyNoticeModalOpen} onClose={closePrivacyNoticeModal} />
    </LayoutPage>
  );
};

function AdditionalCardFee({ additionalCardHolderFee }) {
  if (isNaN(additionalCardHolderFee) || additionalCardHolderFee === 0) {
    return (
      <Text>
        <strong>No additional charges or fees</strong>
      </Text>
    );
  }
  return (
    <Text>
      <strong>${additionalCardHolderFee} additional charges and fees</strong>
    </Text>
  );
}
