import React from 'react';
import styled from '@emotion/styled';
import { byCard } from 'utils';
import { PRODUCT_CODE_AFS, PRODUCT_CODE_TWENTY_EIGHT_DEGREES } from '_config';
import { Grid, Container, Text } from 'basis';

export function Left({ rowIndexMd = 0, rowIndexSm = 0, children }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="0-1" rowSpan={rowIndexSm} rowSpan-md={rowIndexMd} margin-md="0">
      <Container padding-md="0 4 0 0" padding-lg="0 11 0 0">
        <Text textStyle="heading4" as="h4">
          {children}
        </Text>
      </Container>
    </Grid.Item>
  );
}

export function Right({ rowIndexMd = 0, rowIndexSm = 0, children }) {
  return (
    <Grid.Item colSpan="all" colSpan-md="2-7" colSpan-lg="2-5" rowSpan={rowIndexSm} rowSpan-md={rowIndexMd}>
      {children}
    </Grid.Item>
  );
}

export function Split({ children }) {
  return (
    <Grid colsGutter="0" cols="6" rowsGap="4" rowsGap-md="10">
      {children}
    </Grid>
  );
}

export function ResponsiveList({ list, formData, keyPrefix }) {
  const filteredList = list.reduce((acc, item) => {
    if (!item.condition) {
      acc.push(item);
    } else if (item.condition(formData)) {
      acc.push(item);
    }
    return acc;
  }, []);
  const { result } = filteredList.reduce(
    (acc, item, index) => {
      if (item.left) {
        acc.result.push(
          <Left rowIndexMd={index} rowIndexSm={acc.count} key={`${keyPrefix}-left-${index}`}>
            {item.left}
          </Left>,
        );
        acc.count += 1;
      }
      if (item.right) {
        acc.result.push(
          <Right rowIndexMd={index} rowIndexSm={acc.count} key={`${keyPrefix}-right-${index}`}>
            {item.right}
          </Right>,
        );
        acc.count += 1;
      }
      return acc;
    },
    { result: [], count: 0 },
  );
  return <Split>{result}</Split>;
}

export const IconTitleWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 32px;
  & > img {
    margin-right: 8px;
  }

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      font-size: 18px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  padding-bottom: 50px;

  li::before {
    width: 0.3em;
    height: 0.3em;
  }

  li:not(:first-of-type) {
    margin-top: 12px;
  }

  a {
    cursor: pointer;
  }

  h6 {
    font-weight: 500;
  }

  ${props => `
    ${`@media (min-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
        flex-direction: column;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      & > div:first-of-type {
        flex-direction: column;
      }

      & > div {
        max-width: ${props.theme.maxWidth || '1220px'};
        display: flex;
        flex-direction: column;
      }
    }

    ${`@media (max-width: ${props.theme.screenSize.md})`} {
    margin-top: 32px;
      h4 {
        font-size: 20px;
      }
      h6 {
        font-size: 16px;
      }
      padding-bottom: 30px;
    }
  `}
`;

export const LandingContentItem = styled.div`
  display: flex;
  img {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-left: -6px;
    margin-right: 4px;
  }
  ul {
    margin-top: 2px;
    margin-left: 1.5em;
  }
  margin-bottom: 20px;
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      margin-bottom: 12px;
    }
  `}
`;

export const AnchorTag = styled.a`
  font-size: 8px;
  vertical-align: top;
  text-decoration: underline;
  cursor: pointer;
  ${props => `
    color: ${props.theme.a.color};
  `};
`;

export const BannerTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

export const ImageIcons = styled.img`
  height: 68px;
  margin-right: 16px;
  ${props => `
      ${`@media (min-width: ${props.theme.screenSize.lg})`} {
        height: 68px;
      }
  `};
`;

export const HeaderBannerTextStyle = styled.div`
  ${props => `
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    vertical-align: text-top;

    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      line-height: 44px;
    }

    button {
      border-color: inherit;
      border-radius: 4px;
      padding: 12px 16px;
      height: 52px;
      font-size: 17px;
      line-height: 28px;
      color: ${byCard({
        [PRODUCT_CODE_AFS]: props.theme.primaryButtonColor,
        default: '#FFF',
      })};
    }

    sup {
      vertical-align: top;
      position: relative;
      top: -0.2em;
    }

    p {
      white-space: pre-wrap;
    }
  `}
`;

export const ProductNameWrapper = styled.div`
  ${props => `
    color: ${byCard({
      [PRODUCT_CODE_AFS]: '#000',
      default: '#FFF',
    })};
    font-size: 40px;
    font-weight: 600;
    font-family: 'Montserrat';
    white-space: pre-wrap;
    line-height: 60px;
    margin: ${byCard({
      [PRODUCT_CODE_AFS]: '40px 0 40px 0',
      default: '50px 0 20px 0',
    })};

    ${`@media (max-width: ${props.theme.screenSize.sm})`} {
      font-size: 32px;
      line-height: 50px;
      margin: 20px 0;
    }
  `}
`;

export const ProductSubtitle = styled.div`
  font-family: 'Montserrat';
`;

export const Banner = styled.div`
  background: ${byCard({
    [PRODUCT_CODE_AFS]: '#F5F5F7',
    default: 'linear-gradient(129deg, #0032aa 0%, #0062ed 100%)',
  })};
  display: grid;
  align-items: center;
  & > div {
    display: flex;
    justify-content: space-between;
    grid-column: 2/3;
  }

  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.sm})`} {
      & > div {
        width: 100%;
        flex-direction: column-reverse;
      }
    }
    ${`@media (min-width: ${props.theme.screenSize.lg})`} {
      grid-template-columns: 1fr max(1220px) 1fr;

      & > div {
        max-width: 1220px;
      }
      & > div > div:first-child {
        width: ${byCard({
          [PRODUCT_CODE_TWENTY_EIGHT_DEGREES]: '55%',
          default: '45%',
        })}
      }
    }
  `}

  .banner-right {
    grid-column: 3/4;
    height: 100%;
    background: ${byCard({
      [PRODUCT_CODE_AFS]: 'linear-gradient(270deg, #FFF 15.92%, #E2E2E4 79.87%);',
      default: 'inherent',
    })};
  }
`;

export const BannerImageContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    display: block;
    width: auto;
    height: auto;
    margin: auto;
  }
  ${props => `
    ${`@media (max-width: ${props.theme.screenSize.md})`} {
      img {
        width: 234px !important;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  `}
`;

export const BannerImage = ({ children }) => {
  return <BannerImageContainer>{children}</BannerImageContainer>;
};
