export function toConstantCase(str) {
  if (typeof str !== 'string') {
    return str;
  }

  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toUpperCase()
    .replace(/[^A-Z0-9]+/g, '_');
}
