import React from 'react';
import { Container, Input } from 'basis';
import { promoCodePattern } from '_config/_constants';

const PromoCode = () => {
  const promoCodeRegex = new RegExp(promoCodePattern);

  return (
    <Container margin="4 0 10" width-md="50%">
      <Input
        name="promoCode"
        testId="promoCode"
        label="Enter promo code"
        validate={value => !promoCodeRegex.test(value) && 'Please provide a valid promo code or delete to continue.'}
      />
    </Container>
  );
};

export { PromoCode };
