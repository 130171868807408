import { Region } from 'fi-connect';

// TODO: refactor config structure per product
const PRODUCT_CODE_DAVID_JONES_PRESTIGE = 'CCAUDJG';
const PRODUCT_CODE_DAVID_JONES_PREMIERE = 'CCAUDJM';
const PRODUCT_CODE_GO = 'CCAUGOM';
const PRODUCT_CODE_AFS = 'CCAUAFS';

export const phoneCountryCode = '+61';
export const countryName = 'Australia';
const commonPhoneNumber = {
  general: '0800 500 505',
  generalAlt: '+64 9 362 5432',
  originationsTeam: '1300 764 454',
  disputes: '0800 735 192',
  disputesAlt: '(09) 375 0819',
  hardship: '0800 433 033',
  ombudsman: '(04) 499 7612',
  ombudsmanAlt: '0800 888 202',
  appleMerchant: '0800 692 7753',
};

export const lscLink = 'https://servicecentre.latitudefinancial.com.au/';
export const djAssistanceNumber = '1300 550 006';
export const appleAssistanceNumber = '133 622';

export const getPhoneNumbers = productCode => {
  switch (productCode) {
    case PRODUCT_CODE_DAVID_JONES_PRESTIGE:
    case PRODUCT_CODE_DAVID_JONES_PREMIERE:
    case PRODUCT_CODE_GO:
      return {
        ...commonPhoneNumber,
        assistance: djAssistanceNumber,
        assistance2: '1300 46 2273',
      };
    case PRODUCT_CODE_AFS:
      return {
        ...commonPhoneNumber,
        assistance: '1300 131 024',
      };
    default:
      return {
        ...commonPhoneNumber,
        assistance: '1300 764 454',
        assistance2: '1300 552 079',
      };
  }
};

export const minimumResidencyInMonths = 36;

export const minCreditLimit = 500;
// TODO: The max value should come from the decisioning system once application has some form of approval
export const maxCreditLimit = 40000;

export const maxAUCreditLimit = 50000;
export const creditLimitIncrement = 100;

export const minMonthlyPaymentRate = 0.03;

const timezoneOffsetInMs = new Date().getTimezoneOffset() * 60000;

const tomorrowEpoch = new Date().setDate(new Date().getDate() + 1);
const tomorrowLocalDate = new Date(tomorrowEpoch - timezoneOffsetInMs).toISOString().split('T')[0];
export const minExpiryDate = tomorrowLocalDate;

export const minAge = 18;
export const minAgeMaxDate = new Date(new Date().setFullYear(new Date().getFullYear() - minAge) - timezoneOffsetInMs)
  .toISOString()
  .split('T')[0];

export const maxAge = 99;
export const maxAgeDate = new Date(new Date().setFullYear(new Date().getFullYear() - maxAge) - timezoneOffsetInMs);

// AU Mobile numbers start with 04, followed by 7 - 9 numbers
export const validAUMobilePattern = '^04\\d{8}$';
// AU landline numbers start with area codes 03 | 04 | 06 | 07 | 09, followed by 7 numbers
export const validAULandlinePattern = '^0[34679]\\d{7}$';
// address
export const validUnitAddressPattern = '^[0-9]+[a-zA-Z ]*$';
export const validStreetNumberPattern = '^[A-Za-z0-9/-]*$';
export const validStreetNamePattern = `^[A-Za-z '-]*$`;
export const validSuburbPattern = `^[A-Za-z '-]*$`;
export const validPostcodePattern = '^[0-9]{4}$';

export const validAUDriverLicenceNumberPattern = {
  ACT: {
    minLength: 1,
    maxLength: 10,
    pattern: '^[0-9]{1,10}$',
    charType: 'numeric',
  },
  NSW: {
    minLength: 6,
    maxLength: 8,
    pattern: '^[a-zA-Z0-9]{6,8}$',
    charType: 'alphanumeric',
  },
  NT: {
    minLength: 1,
    maxLength: 10,
    pattern: '^[0-9]{1,10}$',
    charType: 'numeric',
  },
  QLD: {
    minLength: 8,
    maxLength: 9,
    pattern: '^[0-9]{8,9}$',
    charType: 'numeric',
  },
  SA: {
    fixedLength: 6,
    pattern: '^[a-zA-Z0-9]{6}$',
    charType: 'alphanumeric',
  },
  TAS: {
    minLength: 6,
    maxLength: 8,
    pattern: '^[a-zA-Z0-9]{6,8}$',
    charType: 'alphanumeric',
  },
  VIC: {
    minLength: 1,
    maxLength: 10,
    pattern: '^[0-9]{1,10}$',
    charType: 'numeric',
  },
  WA: {
    fixedLength: 7,
    pattern: '^[0-9]{7}$',
    charType: 'numeric',
  },
};

export const validAUDriverLicenceCardNumberPattern = {
  ACT: {
    fixedLength: 10,
    pattern: '^[a-zA-Z0-9]{10}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is 10 characters long and can contain letters and numbers.',
  },
  NSW: {
    fixedLength: 10,
    pattern: '^[0-9]{10}$',
    charType: 'numeric',
    messageOverride: 'The card number is 10 characters long and contains only numbers.',
  },
  NT: {
    minLength: 6,
    maxLength: 8,
    pattern: '^[0-9]{6,8}$',
    charType: 'numeric',
    messageOverride: 'The card number is between 6 - 8 characters and contains only numbers.',
  },
  QLD: {
    fixedLength: 10,
    pattern: '^[a-zA-Z0-9]{10}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is 10 characters long and can contain letters and numbers.',
  },
  SA: {
    fixedLength: 9,
    pattern: '^[a-zA-Z0-9]{9}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is 9 characters long and can contain letters and numbers.',
  },
  TAS: {
    fixedLength: 9,
    pattern: '^[a-zA-Z0-9]{9}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is 9 characters long and can contain letters and numbers.',
  },
  VIC: {
    fixedLength: 8,
    pattern: '^[a-zA-Z0-9]{8}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is 8 characters long and can contain letters and numbers.',
  },
  WA: {
    minLength: 8,
    maxLength: 10,
    pattern: '^[a-zA-Z0-9]{8,10}$',
    charType: 'alphanumeric',
    messageOverride: 'The card number is between 8 - 10 characters long and can contain letters and numbers.',
  },
};

export const validEmployerNamePattern = `^[A-Za-z0-9 &,-.:;'"!@#$%^&*()_+=?/]*$`;

export * from './idTypeOptions';
export { auStateOptions } from './auStateOptions';

export const idVerificationFormUrl =
  'https://assets.latitudefinancial.com/forms/cards/identity-verification/gemvisa-nz-idv.pdf';

export const aplyIdUrl = 'https://gemvisa.aplyid.com/forms/redirect';

export const fiConnectRegion = Region.AU;

export const idTypeAUDriverLicence = 'AU_DRIVERS_LICENCE';
