import { setConfig, PRODUCTS, COUNTRY_CODE_AU } from '_config';
import { calculateApplyAndBuy } from 'hooks/useApplyAndBuyQueryParams';
import { getDefaultState } from 'store';

export function updateStoreAndRedirect({ application, nextStep, updateStore, history, storeState }) {
  const countryCode = findCountryCodeByProductCode(application?.productCode);

  const isCountryCodeUpdated = countryCode && countryCode !== storeState.countryCode;
  const isProductCodeUpdated = application?.productCode && application.productCode !== storeState.productCode;

  const {
    applicationId,
    applicationRef,
    isResuming,
    latitudeId,
    isEmailVerified,
    isLatitudeIdPasswordSet,
    previousApplicationStatus,
    ...defaultState
  } = getDefaultState();

  if (isCountryCodeUpdated || isProductCodeUpdated) {
    // when user resumes application without a product code query param
    setConfig({
      countryCode: countryCode || storeState.countryCode,
      productCode: application.productCode,
    });
  }

  updateStore({
    ...(isCountryCodeUpdated || isProductCodeUpdated ? defaultState : {}),
    ...(storeState.applicationStatus ? { previousApplicationStatus: storeState.applicationStatus } : {}),
    applicationErrors: null,
    activeStep: nextStep,
    applicationStatus: application.status,
    ...(storeState.isResuming ? { applicationRetrieveflag: true } : {}),
    ...(countryCode ? { countryCode } : {}),
    ...(application?.applicationRef ? { applicationRef: application.applicationRef } : {}),
    ...(application?.productCode ? { productCode: application.productCode } : {}),
    ...(application?.statusReason ? { applicationStatusReason: application.statusReason } : {}),
    ...(application?.acceptedCreditLimit ? { creditLimitAccepted: application.acceptedCreditLimit } : {}),
    ...(application?.approvedCreditLimit ? { creditLimitApproved: application.approvedCreditLimit } : {}),
    ...(application?.applicationDate ? { applicationDate: application.applicationDate } : {}),
    ...(application?.accountNumber
      ? countryCode === COUNTRY_CODE_AU
        ? { accountNumber: application.accountNumber }
        : { gemVisaAccountNumber: application.accountNumber }
      : {}),
    ...(application?.verifiedIncome ? { bankConnectVerifiedIncome: application.verifiedIncome } : {}),
    ...(application?.medicareChecked ? { eIDVMedicareChecked: application.medicareChecked } : {}),
    ...(application?.passportChecked ? { eIDVPassportChecked: application.passportChecked } : {}),
    ...(application?.poiSourceType ? { incomeVerificationType: application.poiSourceType } : {}),
    ...(application?.dvsConsent ? { dvsConsent: application.dvsConsent } : {}),
    ...(application?.undisclosedDebts && application.undisclosedDebts.length !== 0
      ? { undisclosedDebts: application.undisclosedDebts }
      : {}),
    ...(application?.merchantId && application?.merchantId !== storeState.applyAndBuy.merchantId
      ? { applyAndBuy: calculateApplyAndBuy({ merchantId: application.merchantId, channel: application?.channel }) }
      : {}),
    acquisition: {
      ...storeState.acquisition,
      personalDetails: {
        ...storeState.acquisition.personalDetails,
        ...(application?.firstName ? { firstName: application.firstName } : {}),
        ...(application?.middleName ? { middleName: application.middleName } : {}),
        ...(application?.lastName ? { lastName: application.lastName } : {}),
      },
      employmentDetails: {
        ...storeState.acquisition.employmentDetails,
        ...(application?.employmentStatus ? { employmentStatus: application.employmentStatus } : {}),
      },
      otherDetails: {
        ...storeState.acquisition.otherDetails,
        ...(application?.residentialStatus ? { residentialStatus: application.residentialStatus } : {}),
      },
    },
  });

  history.push(nextStep);
}

function findCountryCodeByProductCode(productCode) {
  const countries = Object.keys(PRODUCTS).map(countryCode => ({
    countryCode,
    productCodes: Object.keys(PRODUCTS[countryCode]),
  }));

  const country = countries.find(({ productCodes }) => productCodes.includes(productCode));

  return country?.countryCode;
}
