import { gql, ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuthHeader } from 'utils';
import { onError } from '@apollo/client/link/error';
import { ERROR_KIND_SYSTEM_ERROR } from 'store';
import { datadogRum } from '@datadog/browser-rum';

export const UNDISCLOSED_DEBT_FIELDS = gql`
  fragment UndisclosedDebtFields on UndisclosedDebt {
    id
    category
    source
    creditProvider
    currentLimit
    checkPrimaryResidencyAssociated
  }
`;

export const ALL_APPLICATION_FIELDS = gql`
  ${UNDISCLOSED_DEBT_FIELDS}
  fragment AllFields on ApplicationResult {
    applicationRef
    status
    firstName
    middleName
    lastName
    employmentStatus
    productCode
    proofOfIncomeVerified
    statusReason
    approvedCreditLimit
    applicationDate
    acceptedCreditLimit
    accountNumber
    verifiedIncome
    medicareChecked
    passportChecked
    idvNoneChecked
    poiSourceType
    dvsConsent
    finalDVSConsentAttempted
    remainingResendSMSAttempts
    undisclosedDebts {
      ...UndisclosedDebtFields
    }
    residentialStatus
    channel
    merchantId
  }
`;

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_ACQ_APP_APPLY_BFF_URL}/gql`,
  credentials: 'include',
});

const authLink = setContext(async (request, { headers }) => {
  const authHeader = await getAuthHeader();
  return {
    headers: !authHeader
      ? headers
      : {
          ...headers,
          Authorization: authHeader,
        },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { response } = operation.getContext();

  const correlationId = response?.headers?.get('X-Correlation-Id');
  const error = graphQLErrors || networkError;

  const message = JSON.stringify({
    root: error.message,
    graphQLErrors,
    networkError,
  });

  error.message = message;
  error.type = 'apply-bff';

  const context = {
    kind: ERROR_KIND_SYSTEM_ERROR,
    correlationId,
    httpStatusCode: response?.status,
    graphQLErrors,
    networkError,
  };

  datadogRum.addError(error, context);
});

export const bffGraphqlClient = new ApolloClient({
  link: from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
});
